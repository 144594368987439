'use client';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../lib/store/Store';
import { VideoBackground } from './VideoBackground';

const mapStateToProps = (state: RootState) => ({
	videoPlaying: state.app.videoPlaying,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type VideoBackgroundReduxProps = ConnectedProps<typeof connector>;
const VideoBackgroundConnected = connector(VideoBackground);
export { VideoBackgroundConnected as VideoBackground };
