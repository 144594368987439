import { useState, useEffect } from 'react';
import { isEmailValid } from '../utils/EmailValidation';

export const useEmailValidation = (email: string) => {
	const [emailIsValid, setEmailIsValid] = useState<boolean>(isEmailValid(email));

	useEffect(() => {
		setEmailIsValid(isEmailValid(email));
	}, [email]);

	return emailIsValid;
};
