import { useSearchParams as useSearchParamsNext, usePathname, useRouter } from 'next/navigation';

export const useSearchParams = () => {
	const searchParams = useSearchParamsNext();
	const pathname = usePathname();
	const router = useRouter();

	const setSearchParams = (newParams: Record<string, string | null>) => {
		const params = new URLSearchParams(searchParams?.toString());
		Object.entries(newParams).forEach(([key, value]) => {
			value ? params.set(key, value) : params.delete(key);
		});
		router.push(`${pathname}?${params.toString()}`);
	};

	return { searchParams, setSearchParams };
};
