import { GraphQLResponse, HttpMethods, RestRequest } from '@maverick/http';
import { SitecoreItemPath } from '../../constants/Sitecore';
import { SitecoreClient } from './client';

const language = 'en';

export const SitecoreService = {
	HomeHeroHeadline: async <T>(): Promise<GraphQLResponse<T>> => {
		const query = `
			query GetHowItWorkTitle($datasource: String!, $language: String!) {
				item(path: $datasource, language: $language) {
					... on _HeadlineHomepage {
						title {
							value
						}
						subtitle {
							value
						}
					}
				}
			}
		`;

		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `?query=${query}&datasource=${SitecoreItemPath.Home.HeadlineHomepageId}&language=${language}`,
		});

		return await SitecoreClient.ExecuteRequest(request);
	},
};
