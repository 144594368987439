import { FC, useRef, useEffect } from 'react';
import { VideoBackgroundReduxProps } from '.';
import styles from './VideoBackground.module.scss';

interface VideoBackgroundProps extends VideoBackgroundReduxProps {
	mp4: string;
	webm?: string;
	ogg?: string;
	className?: string;
}

export const VideoBackground: FC<VideoBackgroundProps> = ({ mp4, webm, ogg, videoPlaying, className }) => {
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (!videoRef.current) return;

		if (videoPlaying) {
			videoRef.current.play();
		} else {
			videoRef.current.pause();
		}
	}, [videoPlaying]);

	const _class = `
		${styles['container']}	
		${className ?? ''}
	`;

	return (
		<div className={_class}>
			<video
				autoPlay
				preload='preload'
				loop
				muted
				playsInline
				ref={videoRef}
				style={{ width: '100%', height: '100%', objectFit: 'cover' }}
			>
				<source src={mp4} type='video/mp4' />
				{webm && <source src={webm} type='video/webm' />}
				{ogg && <source src={ogg} type='video/ogg' />}
			</video>
		</div>
	);
};
