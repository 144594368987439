'use client';
import { useEffect, useState, type FC } from 'react';
import styles from './HowItWorks.module.scss';
import Image from 'next/image';
import { SitecoreService } from '../../../shared/services/sitecore/Sitecore.service';
import { HowItWorksBanner, HowItWorksBannerResponse } from '@maverick/entity';

interface HowItWorksProps {
	isLogged: boolean;
	backgroundImage?: string;
}

export const HowItWorks: FC<HowItWorksProps> = ({ isLogged, backgroundImage }) => {
	const [banner, setBanner] = useState<HowItWorksBanner>();

	useEffect(() => {
		const getHowItWorkBanner = async () => {
			const bannerValue = (await SitecoreService.GetHowItWorkBanner<HowItWorksBannerResponse>()).body?.item;
			setBanner(bannerValue);
		};

		getHowItWorkBanner();
	}, []);

	const handleComponent = (showButton: boolean): JSX.Element => {
		if (!banner) return <div></div>;
		return (
			<div className={styles['container']}>
				<div className={styles['text-section']}>
					<div className={styles['text-content']} data-testid='how-it-works-component'>
						{!!banner?.title?.value && banner?.subtitle?.value && (
							<div className={styles['title']} tabIndex={0}>
								<h1>{banner?.title?.value}</h1>
								<h2>{banner?.subtitle?.value}</h2>
							</div>
						)}
						<div className={styles['border']} />
						{!!banner?.description?.value && (
							<div
								data-testid='list-how-it-works'
								dangerouslySetInnerHTML={{ __html: banner?.description?.value ?? '' }}
							/>
						)}
						{!!banner?.message?.value && (
							<div
								dangerouslySetInnerHTML={{ __html: banner?.message?.value ?? '' }}
								className={styles['message']}
								data-testid='how-it-works-free-reward'
							></div>
						)}
						{showButton && banner?.button.text && (
							<a href={banner?.button.url} className={styles['join-now']} data-testid='join-now-button'>
								{banner?.button.text}
							</a>
						)}
					</div>
				</div>
				{banner?.image?.src && (
					<div className={styles['img-content']} data-testid='image-section'>
						<Image
							className={styles['image']}
							src={banner?.image?.src ?? ''}
							alt={banner?.image?.alt ?? ''}
							width={banner?.image?.width ?? 0}
							height={banner?.image?.height ?? 0}
						/>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			{isLogged ? (
				<div className={styles['logged-container']} data-testid='how-it-works-logged-container'>
					<div style={{ backgroundImage: `url(${backgroundImage})` }} className={styles['background']} />
					<div className={styles['logged-title']} data-testid='how-it-works-logged-title' tabIndex={0}>
						How it works
					</div>
					{handleComponent(false)}
				</div>
			) : (
				handleComponent(true)
			)}
		</>
	);
};
