import { GraphQLResponse, HttpMethods, RestRequest } from '@maverick/http';
import { SitecoreItemPath } from '../../constants/Sitecore';
import { SitecoreClient } from './client';

const language = 'en';

export const SitecoreService = {
	GetHowItWorkBanner: async <T>(): Promise<GraphQLResponse<T>> => {
		const query = `
			query GetHowItWorkBanner($datasource: String!, $language: String!) {
				item(path: $datasource, language: $language) {
					... on _SplitBanner {
						title {
							value
						}
						subtitle {
							value
						}
						description {
							value
						}
						message {
							value
						}
						image {
							alt,
							src,
							width,
							height,
						}
						button {
							text,
							url,
							target,
						}
					}
				}
			}
		`;

		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `?query=${query}&datasource=${SitecoreItemPath.Shared.HowItWorksBannerDataSourceId}&language=${language}`,
		});

		return await SitecoreClient.ExecuteRequest(request);
	},
	GetFrequentlyAskedQuestions: async <T>(): Promise<GraphQLResponse<T>> => {
		const query = `
			query GetFrequentlyAskedQuestions($datasource: String!, $language: String!) {
				item(path: $datasource, language: $language) {
					... on _AccordionList {
						title {
							value
						}
						description {
							value
						}
						accordionItem {
							targetItems {
								title: field(name: "title") {
									value
								}
								description: field(name: "description") {
									value
								}
							}
						}
						columns {
							value
						}
					}
				}
			}
		`;

		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `?query=${query}&datasource=${SitecoreItemPath.Shared.FrequentlyAskedQuestionsDataSourceId}&language=${language}`,
		});

		return await SitecoreClient.ExecuteRequest(request);
	},
};
